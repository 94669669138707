const API_URL = "https://agaramapi.clanizon.com/"; // local api
// const API_URL = 'http://localhost:3002/'
const BASE_URL_AUTH = API_URL + "api/auth/";
const BASE_URL = API_URL + "api/";

const TICKET_API_URL = "http://3.109.210.138:5000/"
const TICKET_BASE_URL = TICKET_API_URL + "api/";

const APQP_BASE_URL = "http://13.127.33.217:7000/api/";

const constants = {
    URL: {
        LOGIN: BASE_URL_AUTH + "signin",
        SIGNUP: BASE_URL_AUTH + "signup",
        SIGNOUT: BASE_URL_AUTH + "signout",
        VERIFY_OTP: BASE_URL_AUTH + "verify-otp",
        SESSION: BASE_URL + "session",
        LEAVE_LIST: BASE_URL + "leave/user/",
         LEAVE_STATUS: BASE_URL + "leave/status/",
        LEAVE_REPORT: BASE_URL + "leave/leaveReport",
        GET_LEAVE_REPORT: BASE_URL + "leave/pendingRequest",
        APPLY_LEAVE: BASE_URL + "leave",
        GET_LEAVE_MANAGER: BASE_URL + "leave/manager/",
        PAYSLIP_LIST: BASE_URL + "payslip?user_id=",
        APPLY_PAYSLIP: BASE_URL + "payslip",
        DELETE_PAYSLIP: BASE_URL + "payslip/",
        // PAYSLIP_LIST: BASE_URL+'payslip',
        ADD_EMPLOYEE: BASE_URL + "user",
        UPDATE_EMPLOYEE_PROFILE: BASE_URL + "user/",
        EMPLOYEE_LIST: BASE_URL + "user",

        ADD_TICKET: TICKET_BASE_URL + "ticketsV2",
        MY_TICKETS: TICKET_BASE_URL + "ticketsV2/created-by-me/",
        ASIGNED_TICKETS: TICKET_BASE_URL + "tickets/assigned-to-me/",
        UPLOAD_PICTURE: TICKET_BASE_URL + "file/upload-multiple",



        
        METADATA_DEPARTMENT: BASE_URL + "department",
        LEAVE_CONFIG_LIST: BASE_URL + "leaveConfig/user/",
        ADD_LEAVE_CONFIG: BASE_URL + "leaveConfig",
        ADD_TYPES_IN_LEAVE_CONFIG: BASE_URL + "leaveTypes",
        ADD_CATEGORY_IN_LEAVE_CONFIG: BASE_URL + "leaveCategory",
        GET_COMPENSATION_BY_MANAGER_ID: BASE_URL + "leave/compensation/manager/",
        APPLY_LEAVE_COMPENSATION: BASE_URL + "leave/compensation/",
        UPDATE_COMPENSATION_STATUS: BASE_URL + "leave/compensation/status/",
        ALLOWED_LEAVE_TYPES: BASE_URL + "leave/allowedTypes/",
        GET_PAYSLIP: BASE_URL + "payslip/",
        PAYSLIP_RELEASE: BASE_URL + "paysliprelease",
        PAYSLIP_DROPDOWN_LIST: BASE_URL + "payslip/dropdownList/",
        PUNCH_IN: BASE_URL + "punchIn",
        PUNCH_OUT: BASE_URL + "punchOut",
        EMAIL_VERIFICATION: BASE_URL + "userVerification",
        RESET_PASSWORD: BASE_URL + "user/resetPassword/",
    },

    APQP_UPL: {
        CLIENT_REGISTRY: APQP_BASE_URL + "clientRegistry",
        TASK_HEADER: APQP_BASE_URL + "taskHeader",
        SUB_TASKS: APQP_BASE_URL + "subTasks",
        PROJECTS: APQP_BASE_URL + "project",
        PROJECTS_DASHBOARD: APQP_BASE_URL + "projectDashboard",
        PROJECTS_OVERALL_COUNT: APQP_BASE_URL + "overAllCards",
        PROJECTS_OVERALL_DASHBOARD: APQP_BASE_URL + "allProjectDashboard",
        UPLOAD_ATTACHMENTS: APQP_BASE_URL + "file/upload-multiplePDFS",

        PROJECT_ASSIGNED_BY_USER_ID: APQP_BASE_URL + "projSubTaskByAssignedTo/"
    }
};

export default constants;
