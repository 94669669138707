import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { PrimeIcons } from "primereact/api";

import { AppTopbar } from "./AppTopbar";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import Punch from "./components/PunchTable";
import UserPayslip from "./components/UserPayslip";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import "./style/override.css";
import { useStoreActions, useStoreState } from "easy-peasy";
import Employee from "./components/Employee";
import AdminPaySlip from "./components/AdminPaySlip";
import Profile from "./components/Profile";
import constants from "./constants/constants";
import axios from "axios";
import LeaveTab from "./components/LeaveTab";
import getHeaders from "./constants/utils";
import CustomRoute from "./CustomRoute";
import ClientRegistry from "./components/projects/ClientRegistry";
import ProjectsDefinition from "./components/projects/ProjectsDefinition";
import Projects from "./components/projects/Projects";
import DashboardProjects from "./components/projects/DashboardProjects";
import Settings from "./components/Settings";
import { useHistory } from 'react-router-dom';
import SessionExpiredModel from "./SessionExpiredModel";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [isAuthScreen, setAuthScreen] = useState(true);
    const user = useStoreState((state) => state.loginModel.user);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (["/login", "/register"].includes(location.pathname)) {
            console.log(location.pathname);
            setAuthScreen(true);
            setStaticMenuInactive(true);
        } else {
            console.log(location.pathname);
            setAuthScreen(false);
            setStaticMenuInactive(false);
        }
        // console.log(location.pathname)
    }, [location.pathname]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "",
            items: 
                user?.permissions?.includes("SU_ADMIN") ?
                [{ label: "Employee", icon: PrimeIcons.USERS, to: "/app/defaultnav" }] : 
                [{ label: "Punch", icon: PrimeIcons.HISTORY, to: "/app/defaultnav" }]
        },
        {
            label: "",
            items: [,
                { label: "Leave", icon: PrimeIcons.CALENDAR_MINUS, to: "/app/leavetab" },
            ],
        },
        {
            label: "",
            items: 
                user?.permissions?.includes("PAYSLIP_ADMIN") || user?.permissions?.includes("SU_ADMIN") ?
                    [{ label: "Payslip", icon: PrimeIcons.BOOK, to: "/app/adminpaySlip" }] : 
                    [{ label: "Payslip", icon: PrimeIcons.BOOK, to: "/app/payslip" }]
        },
        {
            label: "",
            items: 
                user?.permissions?.includes("EMPLOYEE_ADMIN") ?
                    [{ label: "Employee", icon: PrimeIcons.USERS, to: "/app/employee"}] : null
        },
        {
            label: "",
            items: 
                user?.permissions?.includes("CONFIG_ADMIN") || user?.permissions?.includes("SU_ADMIN") ?
                    [{ label: 'Settings', icon: 'pi pi-fw pi-qrcode', to: '/app/settings' }] : null
        },
        {
            label: "",
            items: [
                { label: "Profile", icon: PrimeIcons.USER_EDIT, to: "/app/profile" }
            ],
        },
    ];

    const Adminmenu = [
        {
            label: "",
            items: [{ label: "Employee", icon: PrimeIcons.USERS, to: "/app/defaultnav" },
            { label: "Payslip", icon: PrimeIcons.BOOK, to: "/app/adminpaySlip" },
            { label: "Leave", icon: PrimeIcons.CALENDAR_MINUS, to: "/app/leavetab", },
            { label: 'Settings', icon: 'pi pi-fw pi-qrcode', to: '/app/settings' },
            // {
            //     label: 'Projects',
            //     icon: 'pi pi-fw pi-file',
            //     items: [
            //         { label: 'Dashboard', icon: 'pi pi-fw pi-qrcode', to: '/app/projects-dashboard' },
            //         { label: 'Projects', icon: 'pi pi-fw pi-search', to: '/app/projects-projects' },
            //         { label: 'Definition', icon: 'pi pi-fw pi-check-square', to: '/app/projects-definition' },
            //         { label: 'Client Registry', icon: 'pi pi-fw pi-calendar-plus', to: '/app/projects-client-registry' },
            //     ]
            // }
            ]
        },
    ];

    const setemployeeList = useStoreActions((actions) => actions.employeeModel.setemployeeList);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(constants.URL.EMPLOYEE_LIST, {
                headers: getHeaders(),
            })
            .then((resp) => {
                setemployeeList(resp.data.results);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    // session expired
    const [sessionExpired, setSessionExpired] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const tokenExpiryHandler = () => {
            setSessionExpired(true);
        };

        const tokenExpiryTime = new Date(user?.expiresAt).getTime();
        const currentTime = new Date().getTime();
        const timeUntilExpiry = tokenExpiryTime - currentTime;
        // console.log(tokenExpiryTime, currentTime, (tokenExpiryTime - currentTime));

        if (timeUntilExpiry > 0) {
            setTimeout(tokenExpiryHandler, timeUntilExpiry);
        } else {
            tokenExpiryHandler();
        }

        return () => clearTimeout(tokenExpiryHandler);
    }, []);

    const handleSessionExpired = () => {
        setSessionExpired(false);
        history.push('/');
    };
    // console.log(new Date(user?.expiresAt));

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            {isAuthScreen === false && <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />}
            {isAuthScreen === false && (
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
            )}
            <SessionExpiredModel visible={sessionExpired} onHide={handleSessionExpired} />

            <div className="layout-main-container">
                <div className="layout-main rightContent-card">
                    {/* <Route path="/app/defaultnav" component={roles === "EMPLOYEE" ? Punch : Employee} /> */}
                    {/* <Route path="/dashboard" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
                    {/* <Route path="/app/payslip" component={UserPayslip} /> */}
                    {/* <Route path="/app/leave" component={Leave} /> */}
                    {/* <Route path="/app/adminpayslip" component={AdminPaySlip} /> */}
                    {/* <Route path="/app/test" component={TestScore} /> */}
                    {/* <Route path="/app/profile" component={Profile} /> */}
                    {/* <Route path="/app/leavetab" component={LeaveTab} /> */}
                    {/* <Route path="/App/DefaultNav" component={Employee} /> */}
                    {/* <Route path="/adminPage" component={AdminScreen} /> */}
                    <CustomRoute
                        path="/app/defaultnav" component={user?.permissions?.includes("SU_ADMIN") ? Employee : Punch}
                        allowedRoles={["EMPLOYEE", "SU_ADMIN", "PAYSLIP_ADMIN", "EMPLOYEE_ADMIN", "CONFIG_ADMIN"]}
                        currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/employee" component={Employee}
                        allowedRoles={["SU_ADMIN", "EMPLOYEE_ADMIN"]}
                        currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/payslip" component={UserPayslip}
                        allowedRoles={["EMPLOYEE", "EMPLOYEE_ADMIN", "CONFIG_ADMIN"]}
                        currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/leavetab" component={LeaveTab}
                        allowedRoles={["EMPLOYEE", "SU_ADMIN", "PAYSLIP_ADMIN", "EMPLOYEE_ADMIN", "CONFIG_ADMIN"]}
                        currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/adminpayslip" component={AdminPaySlip}
                        allowedRoles={["SU_ADMIN", "PAYSLIP_ADMIN"]} currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/profile" component={Profile}
                        allowedRoles={["EMPLOYEE", "SU_ADMIN", "PAYSLIP_ADMIN", "EMPLOYEE_ADMIN", "CONFIG_ADMIN"]}
                        currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/settings" component={Settings} allowedRoles={["SU_ADMIN", "CONFIG_ADMIN"]} currentUserRoles={user?.permissions} />
                    {/* <CustomRoute
                        path="/app/dashboard-my-ticket" component={DashboardTicket}
                        allowedRoles={["SU_ADMIN"]}
                        currentUserRoles={user?.permissions}
                    />
                    <CustomRoute
                        path="/app/ticket-detail" component={TicketDetail}
                        allowedRoles={["SU_ADMIN"]}
                        currentUserRoles={user?.permissions}
                    /> */}



                    <CustomRoute path="/app/projects-dashboard" component={DashboardProjects}
                        allowedRoles={["SU_ADMIN"]} currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/projects-projects" component={Projects}
                        allowedRoles={["SU_ADMIN"]} currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/projects-definition" component={ProjectsDefinition}
                        allowedRoles={["SU_ADMIN"]} currentUserRoles={user?.permissions} />
                    <CustomRoute path="/app/projects-client-registry" component={ClientRegistry}
                        allowedRoles={["SU_ADMIN"]} currentUserRoles={user?.permissions} />
                </div>

                {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
            </div>

            {/* <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
