import { useForm } from "react-hook-form";
// import "./signup.css";
import "./login.css";
import React, { useState, useRef } from "react";
import clanizon from "./Images/clanizon.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import constants from "./constants/constants";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

const SignUp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [signUpResp, setSignUpResp] = useState();
    let history = useHistory();
    const toast = useRef(null);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        let websiteUrlString = data?.websiteUrl || '';
        // Add protocol if missing
        if (!websiteUrlString.startsWith('http://') && !websiteUrlString.startsWith('https://')) {
            websiteUrlString = 'https://' + websiteUrlString;
        }
        const websiteUrl = new URL(websiteUrlString);
        const websiteDomain = websiteUrl.hostname.replace(/^www\./, '').toLowerCase();
        const emailDomain = data.userEmail.split("@")[1].toLowerCase();

        if (emailDomain === websiteDomain) {
            const payload = {
                org_info: {
                    name: data.orgName,
                    website: data.websiteUrl,
                    domains: [websiteDomain],
                    address: data.address
                },
                user_info: {
                    first_name: data.firstName,
                    last_name: data.lastName,
                    email: data.userEmail,
                    password: data.password,
                    mobile_no: data.mobNumber,
                },
            };
            // console.log(data);

            setIsLoading(true);
            axios
                .post(constants.URL.SIGNUP, payload)
                .then((resp) => {
                    console.log(resp.data.results);
                    setSignUpResp(resp.data.results);
                    setVisible(true);
                })
                .catch((e) => {
                    console.error(e);
                    toast.current.show({ severity: "error", summary: "Error", detail: e.response.data.message });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Website url and mail id is not same" });
        }
    };
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const gotoSignIn = (to) => {
        history.replace(to);
    };
    const [visible, setVisible] = useState(false);

    const handleOTP = () => {
        // alert("hai")
        const payload = {
            id: signUpResp?.otp?._id,
            otp_code: "123456",
        };
        axios
            .post(constants.URL.VERIFY_OTP, payload)
            .then((resp) => {
                console.log(resp.data.results);
                toast.current.show({ severity: "success", summary: "Success", detail: "Success" });
                gotoSignIn("/");
            })
            .catch((e) => {
                console.error(e);
                toast.current.show({ severity: "error", summary: "Error", detail: "OTP Verification Failed" });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex" style={{ background: "#ffffff" }}>
                <div className="md:col-5 hidden md:flex p-0" style={{ height: "100vh" }}>
                    <img src={clanizon} alt="clanizon" className="clanizonlogo" />
                </div>

                <div className="col-12 md:col-7 flex align-items-center justify-content-center">
                    <div className=" p-4 w-full lg:w-10">
                        <div className="text-left mb-5">
                            {/* <img src="images/blocks/logos/hyper.svg" alt="hyper" height="50" className="mb-3" /> */}
                            <h5 className="text-900 text-3xl font-medium mb-3">Sign Up</h5>
                            {/* <span className="text-600 font-medium line-height-3">Don't have an account?</span> */}
                            {/* <button className="p-link font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</button> */}
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
                            <h5>Organization</h5>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="email1">
                                        Organization Name<span className="p-error">*</span>
                                    </label>
                                    <InputText
                                        type="text"
                                        {...register("orgName", {
                                            required: true,
                                            maxLength: 20, // set the maximum length to 20 characters
                                            pattern: /^[A-Za-z]+$/,
                                        })}
                                    />
                                    {errors?.orgName?.type === "required" && <p>This field is required</p>}
                                    {errors?.orgName?.type === "maxLength" && <p>The name cannot be longer than 20 characters</p>}
                                    {errors?.orgName?.type === "pattern" && <p>Only letters are allowed</p>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="websiteUrl">
                                        Website Url<span className="p-error">*</span>
                                    </label>
                                    <InputText
                                        {...register("websiteUrl", {
                                            required: true,
                                            pattern: /^(https:\/\/)(www\.)[a-z0-9]+\.[a-z]{2,}(\/.*)?$/i,
                                            message: "Please enter a valid URL",
                                        })}
                                    />
                                    {errors?.websiteUrl?.type === "required" && <p>This field is required</p>}
                                    {errors?.websiteUrl?.type === "pattern" && <p>Please enter a valid URL. eg: https://www.example.com</p>}
                                </div>
                            </div>

                            <h5>Personal Details</h5>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="firstname2">
                                        Firstname<span className="p-error">*</span>
                                    </label>
                                    <InputText
                                        id="firstname2"
                                        type="text"
                                        {...register("firstName", {
                                            required: true,
                                            maxLength: 15, // set the maximum length to 15 characters
                                            pattern: /^[A-Za-z]+$/,
                                        })}
                                    />
                                    {errors?.firstName?.type === "required" && <p>This field is required</p>}
                                    {errors?.firstName?.type === "maxLength" && <p>The name cannot be longer than 15 characters</p>}
                                    {errors?.firstName?.type === "pattern" && <p>Only letters are allowed</p>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="lastname2">
                                        Lastname<span className="p-error">*</span>
                                    </label>
                                    <InputText
                                        id="lastname2"
                                        type="text"
                                        {...register("lastName", {
                                            required: true,
                                            maxLength: 15, // set the maximum length to 15 characters
                                            pattern: /^[A-Za-z]+$/,
                                        })}
                                    />
                                    {errors?.lastName?.type === "required" && <p>This field is required</p>}
                                    {errors?.lastName?.type === "maxLength" && <p>The name cannot be longer than 15 characters</p>}
                                    {errors?.lastName?.type === "pattern" && <p>Only letters are allowed</p>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="firstname2">
                                        Business Email Id<span className="p-error">*</span>
                                    </label>
                                    <InputText
                                        id="firstname2"
                                        type="text"
                                        {...register("userEmail", {
                                            required: true,
                                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            // maxLength: 20, // set the maximum length to 20 characters
                                            message: "Please enter a valid email address",
                                        })}
                                    />
                                    {errors.userEmail && errors.userEmail.type === "required" && <p>This field is required</p>}
                                    {/* {errors?.userEmail?.type === "maxLength" && <p>The email address cannot be longer than 20 characters</p>} */}
                                    {errors.userEmail && errors.userEmail.type === "pattern" && <p>Please enter a valid email address</p>}
                                </div>

                                <div className="field col-12 md:col-6">
                                    <label htmlFor="lastname2">
                                        Password<span className="p-error">*</span>
                                    </label>
                                    <div className="password-input-wrapper" style={{ background: "#ffffff", display: "flex" }}>
                                        <InputText
                                            id="password"
                                            type={showPassword ? "text" : "password"}
                                            {...register("password", {
                                                required: true,
                                                minLength: 8, // set minimum password length to 8 characters
                                                maxLength: 20, // set maximum password length to 20 characters
                                                // pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+$/, // include at least one lowercase letter, one uppercase letter, and one digit
                                            })}
                                        />

                                        <Button type="button" className="password-toggle" onClick={togglePasswordVisibility} icon={showPassword ? "pi pi-eye-slash" : "pi pi-eye"} />
                                    </div>
                                    {errors?.password?.type === "required" && <p>This field is required</p>}
                                    {errors?.password?.type === "minLength" && <p>Password should be at least 8 characters long</p>}
                                    {errors?.password?.type === "maxLength" && <p>Password should not exceed 20 characters</p>}
                                    {/* {errors?.password?.type === "pattern" && <p>Password should include at least one lowercase letter, one uppercase letter, and one digit</p>} */}
                                </div>

                                <div className="field col-12 md:col-6">
                                    <label htmlFor="lastname2">
                                        Phone Number<span className="p-error">*</span>
                                    </label>
                                    <InputText
                                        id="lastname2"
                                        type="text"
                                        {...register("mobNumber", {
                                            required: true,
                                            pattern: {
                                                value: /^\d{10}$/, // regex pattern to match 10 digit phone numbers
                                                message: "Invalid phone number format",
                                            },
                                        })}
                                    />
                                    {errors?.mobNumber?.type === "required" && <p>This field is required</p>}
                                    {errors?.mobNumber?.type === "pattern" && <p>{errors.mobNumber.message}</p>}
                                </div>
                                
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="address">
                                        Address<span className="p-error">*</span>
                                    </label>
                                    <InputText
                                        id="address"
                                        type="text"
                                        {...register("address", {
                                            required: true,
                                        })}
                                    />
                                    {errors?.address?.type === "required" && <p>This field is required</p>}
                                </div>
                            </div>

                            <Button label="Create Account" className="w-full mt-3" />
                            <div className="signup-links mt-2">
                                <span className="text-600 font-medium line-height-3">Already have an account?</span>
                                <span className="text-blue-500 cursor-pointer" onClick={() => gotoSignIn("/")}>
                                    {" "}
                                    Sign In
                                </span>
                            </div>
                        </form>
                        <Dialog header="Verify Your Email" visible={visible} style={{ width: "40vw", fontSize: "35px" }} onHide={() => setVisible(false)}>
                            <p className="parag">
                                We send to the e-mail <span className="mail">{signUpResp?.user?.email}</span>
                            </p>
                            <div className="verify-input1">
                                <InputText keyfilter="int" className="verify-input" minLength={1} maxLength={1} />
                                <InputText keyfilter="int" className="verify-input" minLength={1} maxLength={1} />
                                <InputText keyfilter="int" className="verify-input" minLength={1} maxLength={1} />
                                <InputText keyfilter="int" className="verify-input" minLength={1} maxLength={1} />
                                <InputText keyfilter="int" className="verify-input" minLength={1} maxLength={1} />
                                <InputText keyfilter="int" className="verify-input" minLength={1} maxLength={1} />
                            </div>
                            <Button className="verify_btn" label="Verify Code" onClick={handleOTP} />
                            {/* <p className="read">Resend Otp</p> */}
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
