import React, { useEffect } from "react";
import { TabMenu } from 'primereact/tabmenu';
import AllPaySlip from "./AllPaySlip";
import "../style/Pay.css";
import PayslipRelease from "./PayslipRelease";
import { useStoreActions, useStoreState } from "easy-peasy";

const AdminPaySlip = () => {
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    const payItems = [
        { label: 'Find Payslip'},
        { label: 'Payslip Release'},
    ];

    return (
        <>
            <div className="pay_card">
                <TabMenu model={payItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />

                {activeIndex === 0 && <AllPaySlip />}
                {activeIndex === 1 && <PayslipRelease />}
            </div>
        </>
    )
}
export default AdminPaySlip;