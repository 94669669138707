import React, { useEffect } from "react";
import { TabMenu } from 'primereact/tabmenu';
import { useStoreActions, useStoreState } from "easy-peasy";
import DepartmentConfigSetting from "./settings/DepartmentConfigSetting";
import LeaveConfigSetting from "./settings/LeaveConfigSetting";
import LeaveCategoryConfigSetting from "./settings/LeaveCategoryConfigSetting";
import LeaveTypeConfigSetting from "./settings/LeaveTypeConfigSetting";

const Settings = () => {
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    const items = [
        { label: 'Department' },
        { label: 'Leave' },
        { label: 'Leave Category' },
        { label: 'Leave Type' },
    ];

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    return (
        <>
            <div className="card">
                <div className="">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                </div>
                {activeIndex === 0 && <DepartmentConfigSetting />}
                {activeIndex === 1 && <LeaveConfigSetting />}
                {activeIndex === 2 && <LeaveCategoryConfigSetting />}
                {activeIndex === 3 && <LeaveTypeConfigSetting />}
            </div>
        </>
    )
}

export default Settings;